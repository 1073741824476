import img1 from "../img/Pro/1.png";
import img2 from "../img/Pro/2.png";
import img3 from "../img/Pro/3.png";
import img4 from "../img/Services/3.png";


const ProductData = [
    {
        id: 1,
        name: 'Daisy DND Nail Gel',
        img: img1,
        desc: 'Our salon proudly features Daisy DND Nail Gel, a non-toxic and Cruelty-Free product! DND delivers all the trending colors for each season. Their commitment to safety and style makes them our top pick!'
    },
    {
        id: 2,
        name: 'Chisel and Kiara Sky',
        img: img2,
        desc: 'Chisel Nail Art is a leading nail care products company renowned for its unparalleled innovation in Ombre Nail Powder. We offer Kiara Sky because we align with their mission to provide quality products that allow you to unleash your inner artist, express yourself creatively, and share your unique style with the world.'
    },
    {
        id: 3,
        name: "Le'K Foot Scrubs",
        img: img3,
        desc: 'Our salon offers luxury and health creme de la creme with our fragrant and therapeutic foot scrubs. Choose the one that works for your needs: immunity, relaxation, and more.'
    },
    {
        id: 4,
        name: 'Keyano Aromatics',
        img: img4,
        desc: 'Our discerning clients enjoy Keyano products because they are luxurious and natural with therapeutic healing properties. Keyano Aromatics, in the tradition of the great spas of Europe, utilizes only the finest of raw materials.'
    },
];

export default ProductData;