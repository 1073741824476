import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faYelp  , faFacebook , faInstagram , faGoogle} from "@fortawesome/free-brands-svg-icons";
import {faAddressCard} from "@fortawesome/free-regular-svg-icons";
import Logo from "../img/Logo.png";
function SocialTree(){
    return(
        <div className="flex font-newFont space-y-8 items-center justify-center py-6 px-4 flex-col overflow-y-hidden overflow-x-hidden bg-gradient-to-t from-yellow-100 to-orange-100 bg-center w-screen h-screen bg-cover bg-no-repeat">
            <div className= "w-44 mx-auto cursor-pointer">
                <img alt="#" src={Logo} className="rounded-lg hover:opacity-50 w-full h-full transition ease-in-out delay-150 duration-200"/>
            </div>
            <div className="group mx-auto  group hover:bg-rose-600 border-2 w-full h-16 md:w-4/5 md:h-20 rounded-lg border-rose-600 shadow-lg  hover:opacity-50 transition ease-in-out delay-150 duration-200">
                <a href = "https://www.yelp.com/writeareview/biz/w0C0lFTNhH5t06r-H9WmlQ?return_url=%2Fbiz%2Fw0C0lFTNhH5t06r-H9WmlQ&review_origin=biz-details-war-button" target="_blank" rel="noreferrer">
                    <button className="w-full md:text-3xl group-hover:text-white text-rose-600 italic font-semibold h-full inline-flex items-center justify-center">
                        <FontAwesomeIcon className="mx-2 group-hover:text-white md:mx-4 overflow-hidden text-rose-500 transition ease-in-out delay-150 duration-200 text-3xl md:text-5xl" icon={faYelp}></FontAwesomeIcon>
                    Yelp</button>
                </a>
            </div>
            <div className="group mx-auto  hover:bg-black  border-2 group w-full h-16 md:w-4/5 md:h-20 rounded-lg border-black shadow-lg  hover:opacity-50 transition ease-in-out delay-150 duration-200">
                <a href = "/" target="_blank" rel="noreferrer">
                    <button className="w-full md:text-3xl italic group-hover:text-white font-semibold text-black h-full inline-flex items-center justify-center">
                        <FontAwesomeIcon className="mx-2 md:mx-4 text-4xl  group-hover:text-white md:text-5xl rounded-lg shadow-md transition ease-in-out delay-150 duration-200" icon={faAddressCard}></FontAwesomeIcon>
                    Website</button>
                </a>
            </div>
            <div className="group  mx-auto  group hover:bg-sky-400 border-2 w-full h-16 md:w-4/5 md:h-20 rounded-lg border-sky-400 shadow-lg  hover:opacity-50 transition ease-in-out delay-150 duration-200">
                <a href = "https://www.facebook.com/profile.php?id=61550839471126" target="_blank" rel="noreferrer">
                    <button className="w-full h-full md:text-3xl group-hover:text-white text-sky-400 font-semibold italic inline-flex items-center justify-center mx-2">
                        <FontAwesomeIcon className="mx-2 md:mx-4  text-4xl md:text-5xl group-hover:text-white text-sky-600 " icon={faFacebook}></FontAwesomeIcon>
                    Facebook</button>
                </a>
            </div>
            <div className="group  mx-auto hover:bg-pink-500 group border-2 w-full h-16 md:w-4/5 md:h-20 rounded-lg border-pink-300 shadow-lg  hover:opacity-50 transition ease-in-out delay-150 duration-200">
                <a target="_blank" rel="noreferrer" href = "https://www.instagram.com/ttbeautyspapleasant/">
                    <button className="w-full md:text-3xl group-hover:text-white text-pink-500 italic font-semibold h-full inline-flex items-center justify-center mx-2">
                        <FontAwesomeIcon className="mx-2 md:mx-4 text-4xl md:text-5xl rounded-lg group-hover:text-white text-pink-500 " icon={faInstagram}></FontAwesomeIcon>
                    Instagram</button>
                </a>
            </div>
            <div className="group  mx-auto hover:bg-green-500 group border-2 w-full h-16 md:w-4/5 md:h-20 rounded-lg border-green-500 shadow-lg  hover:opacity-50 transition ease-in-out delay-150 duration-200">
                <a target="_blank" rel="noreferrer" href = "https://g.page/r/CY66BPgn7uruEBM/review">
                    <button className="w-full md:text-3xl group-hover:text-white text-green-500 italic font-semibold h-full inline-flex items-center justify-center mx-2">
                        <FontAwesomeIcon className="mx-2 md:mx-4 text-4xl md:text-5xl rounded-lg group-hover:text-white text-green-500 " icon={faGoogle}></FontAwesomeIcon>
                    Google</button>
                </a>
            </div>
        </div>
    )
};
export default SocialTree;