import img1 from "../img/Services/1.png";
import img2 from "../img/Services/2.png";
import img3 from "../img/Services/3.png";
import img4 from "../img/Services/4.png";
import img5 from "../img/Services/5.png";
import img6 from "../img/Services/6.png";
import img9 from "../img/Services/9.png";
import img8 from "../img/Services/8.png";
import img10 from "../img/Services/10.png";
import img11 from "../img/Services/11.png";

const ServicesData = [
    {
        id: 1,
        name: 'Manicure',
        services: [
            {
                name: 'Natural Manicure',
                desc: 'Starting with Herbal Mineral Bath soak, followed by a gentle exfoliating, with Liquid Body Lufra and finishing with a soft yet intense hydrating Baobab Body Butter for all day moisturizing.',
                price: '$25'
            },

            {
                name: 'Icedancer Manicure',
                desc: 'Starting with a Herbal Mineral Bath soak, followed by a gentle exfoliating with Liquid Body Lufra and topped off with the Revitalizing Ice Dancer Invigorating Leg Gel massage and hot towel wrap that revives, stimulates and soothes tired, achy arms and hands. Finish with a soft yet intense hydrating Baobab Body Butter to deliver all day moisturizing.',
                price: '$30'
            }
        ],
        img: img1,
    },

    {
        id: 2,
        name: 'Pedicure',
        img: img2,
        services: [

            // Cắt Móng , Clean Heal , TRà Gót Chân , Massage , Wrap Warm Trowel, Color
            {
                name: 'Natural Basic Pedicure',
                desc: "Start with a warm foot soak to relax and soften the skin. Next, we'll carefully clean and trim the cuticles, followed by a gentle heel exfoliation to smooth rough skin. Enjoy a soothing lower leg massage to relieve tension, and then your feet will be wrapped in a warm towel for added relaxation. Finally, we'll finish with a perfect application of your chosen nail color, leaving your feet feeling refreshed and polished.",
                price: 'Pedi $35 / Mani & Pedi Combo $50'
            },

            {
                name: 'Natural Smoothing Pedicure',
                desc: 'Start with a relaxing Herbal Mineral Bath soak, followed by a gentle massage and exfoliation with Liquid Body Lufra. We will carefully trim and shape your nails and cuticles, clean and exfoliate your heels, and provide a soothing lower leg massage with Body Smoother lotion. Enjoy the added comfort of warm towel wraps, and finish with a color application of your choice.',
                price: 'Pedi: $40 / Mani & Pedi Combo: $55'
            },

            {
                name: 'Natural Ice Dancer Pedicure',
                desc: 'Your feet have been long awaiting this rejuvenating pedicure. Say ahh.. to a warm whirlpool soak with Herbal Mineral then a gentle massage with natural Liquid Body Lufra exfoliation. This very special massage includes a calf massage with the Invigorating Ice Dancer Leg Gel and steamy towels wrapped are added for restoring vitality and energizing the legs. Dry heels are also treated with Sole Solution Foot Treatment. Finish with a soft yet intense hydrating Baobab Body Butter for the ultimate pedicure experience.',
                price: 'Pedi: $50 / Mani & Pedi Combo: $65'
            }
        ]
    },

    {
        id: 3,
        name: 'Essential Oils (Pedicure)',
        img: img3,
        services: [
            {
                name: 'FireWalker Pedicure',
                desc: 'Indulge in this guilty pleasure without the guilt. The Firewalker pedicure will leave your feet and legs feeling amazing! Using an Herbal Mineral Bath, a blend of Grapefruit Body Bar and Liquid Body Lufra exfoliating and topping it off with the Revitalizing Ice Dancer Invigorating Leg Gel massage and hot towels wraps brings your pedicure experience to a whole new level. Along with our most famous Sole Solution heels treatment, finger pressure massage techniques using Firewalker Foot Cream we will take all of the tiredness away. Finish with the ultimate soft Baobab Body Butter.',
                price: 'Pedi $60 / Mani & Pedi Combo $75'
            },

            {
                name: 'Peppermint',
                desc: 'Refreshes tired and achy feet: After a long day of standing or walking, our feet often crave relief. A peppermint pedicure is a rejuvenating remedy that brings instant refreshment to tired and achy feet. The cooling soothing properties of peppermint help alleviate fatigue, leaving your feet feeling revitalized and invigorated.',
                price: '60 minutes / $60',
            },

            {
                name: 'Green Tea',
                desc: 'A Green tea pedicure is a type of beauty treatment that uses green tea as an exfoliator. Green tea has many benefits for the skin including Antioxidants that helps to detoxify and protect your skin while then tannins helps to soother and calm irritated skin. The green tea pedicure can help to improve the appearance of the skin and make it feel softer and smoother.	',
                price: '55 minutes / $55'
            },
            {
                name: 'Lavender',
                desc: 'A Lavender pedicure is a popular combination of relaxing and rejuvenating. Lavender also boosts the circulatory systems, which helps increase the flow of oxygen and nutrients to skin.',
                price: '55 minutes / $55'
            },
            {
                name: 'Pumpkin Spice',
                desc: "Apart from its enjoyable sensory benefits, the pumpkin pedicure has numerous benefits that are good for the health of the feet. The exfoliation helps to remove dead skin cells and calluses, which can cause dryness, rough patches, and cracks. The pumpkin's natural enzymes work to soften and hydrate the feet while promoting healthy, balanced skin.",
                price: '60 minutes / $60'
            },
        ]
    },

    {
        id: 4,
        name: 'Facial',
        img: img4,
        services: [
            {
                name: 'Mini Facial',
                desc: 'Pamper yourself with a mini facial on a spa chair, because you deserve to relax and replenish.',
                price: '$30'
            },

            {
                name: 'Basic Facial',
                desc: 'Is a skincare procedure involving removing dead skin cells, pore cleaning, massage with cream and using a specific mask to treat common skin issues. A finest serum to be applied at the end to keep your skin bright and smooth. This multi-step process attempts to nourish and regenerate facial skin, making it appear healthier and younger.',
                price: '$80'
            },

            {
                name: 'HydraFacial',
                desc: 'Its many benefits include a more hydrated, bright, plump, and clear complexion. Plus, it can also improve signs of aging. The treatment reduces fine lines and wrinkles, increases firmness, evens tone, texture, and brown spots, as well as reduces enlarged pores. Known for its main three-step process, the HydraFacial works to deep-clean, exfoliate, and hydrate your skin. This improves your overall skin texture, tone and appearance for healthy, soft and glowing skin.',
                price: '$150'
            }
        ]
    },

    {
        id: 5,
        name: 'Nail Enhancement',
        img: img5,
        services: [
            {
                name: "Acrylic Set with Gel Polish",
                price: "$55 & up"
            },
            {
                name: "Acrylic Fill with Gel Polish",
                price: "$45"
            },
            {
                name: "Acrylic Set with Regular Polish",
                price: "$50 & up"
            },
            {
                name: "Acrylic Fill with Regular Polish",
                price: "$35"
            },
            {
                name: "Acrylic Overlay",
                price: "$55"
            },
            {
                name: "Pink & White Full Set",
                price: "$60 & up"
            },
            {
                name: "Pink Fill",
                price: "$40"
            },
            {
                name: "Pink & White Fill",
                price: "$50 & up"
            },
            {
                name: "Silk Wrap Full",
                price: "$55"
            },
            {
                name: "Silk Wrap Fill",
                price: "$45"
            },

            {
                name: "Gel Builder Full Set",
                price: "$55 & up"
            },
            {
                name: "Gel Builder Fill",
                price: "$45"
            },

            {
                name: "Gel X",
                price: "$55 & up"
            },
            {
                name: "Dip Powder Set",
                price: "$50 & up"
            },

            {
                name: "Gel Manicure",
                price: "$35"
            },
            {
                name: "Gel Pedicure",
                price: "$50"
            },
        ]
    },

    {
        id: 6,
        name: 'Additional Services',
        img: img9,
        services: [
            {
                name: "Hands Polish Change",
                price: "$13"
            },
            {
                name: "Feet Polish Change",
                price: "$15"
            },
            {
                name: "Paraffin Treatment",
                price: "$10"
            },
            {
                name: "Hot Stone",
                price: "$10"
            },
            {
                name: "Classic Sea Salt",
                price: "$10"
            },
            {
                name: "Calling Masque",
                price: "$10"
            },
            {
                name: "Regular French Tips",
                price: "$5"
            },
            {
                name: "Deep French Tips",
                price: "$10 & up"
            },
            {
                name: "Nails Design",
                price: "$5 & up"
            },
            {
                name: "Callus Remove",
                price: "$5 & up"
            }
        ]
    },

    {
        id: 7,
        name: "Kid's Services",
        extras: '12 Years Old And Under',
        img: img6,
        services: [
            {
                name: "Manicure & Pedicure",
                price: "$35"
            },
            {
                name: "Manicure",
                price: "$15"
            },
            {
                name: "Pedicure",
                price: "$25"
            },
            {
                name: "Nail Design",
                price: "Free"
            },
            {
                name: "Polish Change",
                price: "$5"
            }
        ]
    },

    {
        id: 8,
        name: 'Waxing',
        img: img8,
        services: [
            {
                name: "Eyebrows",
                price: "$18"
            },
            {
                name: "Lip",
                price: "$9"
            },
            {
                name: "Chin",
                price: "$9"
            },
            {
                name: "Eyebrows & Lip/Chin",
                price: "$23"
            },
            {
                name: "Eyebrows, Lip & Chin",
                price: "$30"
            },
            {
                name: "Full Face",
                price: "$45 & up"
            },
            {
                name: "Side Burns",
                price: "$25 & up"
            },

            {
                name: "Under Arms",
                price: "$20"
            },
            {
                name: "Half Arms",
                price: "$35 & up"
            },
            {
                name: "Full Arms",
                price: "$45 & up"
            },
            {
                name: "Half Leg",
                price: "$40 & up"
            },
            {
                name: "Full Leg",
                price: "$55 & up"
            },
            {
                name: "Bikini Line",
                price: "$30 & up"
            },
            {
                name: "Brazilian Bikini",
                price: "$55 & up"
            },
            {
                name: "Full Back or Full Decollete (Chest)",
                price: "$50 & up"
            }
        ]
    },

    {
        id: 9,
        name: 'Hair',
        img: img10,
        services: [
            {
                name: 'Men Hair Cut',
                price: '$25 & up'
            },
            {
                name: 'Women Hair Cut',
                price: '$35 & up'
            },
            {
                name: 'Cut Shampoo & Style (w/Curling or Flat Iron)',
                price: '$55 & up'
            },
            {
                name: 'Shampoo & Style (w/Curling or Flat Iron)',
                price: '$40 & up'
            },
            {
                name: 'Perm (Not Include Cut)',
                price: '$100 & up'
            },
            {
                name: 'Relaxer (Permanent Straightening)',
                price: '$250 & up'
            },
            {
                name: 'Color',
                price: '$80 & up'
            },
            {
                name: 'Frosting / Highlights',
                price: '$75 & up'
            }
        ]
    },

    {
        id: 10,
        name: 'Nourishing Hair Wash',
        img: img11,
        services: [
            {
                name: 'Nourishing Hair Wash',
                desc: 'The latest trend because it not only cleanses and cares for the hair but also incorporates acupressure and massage on the head, neck, shoulders, nape and arms. This helps the body relax, effectively reducing stress and fatigue.',
                extra: [
                    'Makeup removal and face cleansing',
                    'Aromatherapy steam treatment',
                    'Facial massage , Hair washing and conditioning',
                    'Head, shoulder, neck and nape massage',
                    'Chest to arm hot stone therapy',
                    'Basic hair drying'
                ],
                add_ons: [
                    {
                        name: 'Moisturizing face mask',
                        price: 'additional $10',
                    },
                    {
                        name: 'Blow Dry with Styling',
                        price: 'additional $25 & up',
                    }
                ],
                price: '$50 / 50 mins'
            },
        ]
    }

];


export default ServicesData;