import React from "react";
export default function Contact(){

    return (
        <section className = "space-y-5 pb-5 md:pb-12 ">
            <div className="bg-gpage3 bg-no-repeat bg-center bg-cover h-[300px] lg:h-[600px] relative">
                <div className=" bg-black w-full h-full bg-opacity-50"></div>
                <div className="top-8 text-white w-full absolute text-3xl h-full flex items-center justify-center cursor-pointer lg:text-6xl">
                    <h2 style={{fontFamily: "Redressed"}} className="">Contact Us</h2>
                </div>
            </div>
            <div className="mx-auto md:max-w-screen-xl cursor-pointer">
                <div className="grid grid-cols-1 text-justify lg:grid-cols-3 ">
                    <div className="col-span-2 md:w-full w-screen p-4 space-y-4 h-full lg:space-y-8 transition ease-in-out duration-200 delay-100 hover:opacity-50">
                        <iframe className="w-full h-[400px] lg:h-[650px] md:px-12" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6292.468246755205!2d-122.058953!3d37.948319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808561475b234457%3A0xeeeaee27f804ba8e!2sT%26T%20Beauty%20Spa!5e0!3m2!1sen!2sus!4v1721612368716!5m2!1sen!2sus" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="p-4 space-y-4 lg:space-y-8 lg:text-justify lg:grid justify-center text-center">
                        <div className="">
                            <div className="grid grid-cols-1 p-4 space-y-5">
                                <div>
                                    <span className="text-3xl text-main-color tracking-wider">See us in person</span>
                                </div>
                                <div className="space-y-3">
                                    <p className="font-newFont">We love our customers and welcome them to visit during our normal business hours of 9:30am - 7:30pm , Monday through Sunday.</p>
                                    <ul className="space-y-2 font-semibold">
                                        <li className=" flex flex-col">
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" target="_blank"  rel="noreferrer" href = "https://maps.app.goo.gl/dVST1Zg84N5NmtLc8">
                                                Address: 1944 Contra Costa Blvd, Pleasant Hill, CA 94523
                                            </a>
                                            <span className=" text-sm font-thin">
                                                Safeway shopping center near Nation’s Giant Hamburgers, next to GMG Dry Cleaner.
                                            </span>
                                        </li>
                                        <li>
                                            <a className="transition text-black t-underline t-underline-black border-b-yellow-500" href="tel:9253499958">
                                                Phone#: (925) 349-9958
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <hr></hr>
                                <div>
                                    <div className="font-semibold text-lg">Business Hours:</div>
                                    <ul className="space-y-4 text-sm mt-4">
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Mon: 9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Tues:  9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Wed:  9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Thu:  9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Fri:  9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sat: 9:30 am - 7:30 pm
                                            </span>
                                        </li>
                                        <li>
                                            <span href="" className=" transition text-black t-underline t-underline-black border-b-yellow-500">
                                                Sun: 10:00 am - 7:00 pm
                                            </span>
                                        </li>
                                    </ul>
                                    <button onClick={() => {
                                        window.location.href = 'tel:9253499958';
                                    }} className=" mt-5 py-3 transition ease-in-out duration-200 delay-100 group border-main-color hover:bg-main-color px-12 border-[1px]">
                                        <span className=" transition ease-in-out duration-200 delay-100 text-lg group-hover:text-white">
                                            Book Now
                                        </span>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}